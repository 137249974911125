.privacy-page {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    font-size: 16px;
}

.privacy-title {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 60px;
}

.privacy-h2 {
    font-size: 20px;
    font-weight: 700;
    margin: 30px 0;
}

.privacy-text {
    margin-bottom: 10px;
}