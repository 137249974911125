.products {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    padding-bottom: 100px;
    row-gap: 50px;
}

@media (max-width: 900px) {
    .products {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }
}