
.added-popup {
    position: fixed;
    top: 100px;
    right: 50px;
    background-color: black;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    align-items: center;
    animation: slide-down 0.1s ease-in-out;
    cursor: pointer;
  }
  
  @keyframes slide-down {
    from {
      transform: translateY(-100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .added-popup-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 5px;
  }

  .added-popup-main {
    font-size: 14px;
    color: white;
  }

  .added-popup-additional {
    display: flex;
    font-size: 12px;
    gap: 20px;
    margin-top: 10px;
    color: rgb(196, 196, 196);
  }

@media (max-width: 900px) {
    .added-popup {
        position: fixed;
        top: 100px;
        right: 20px;
        background-color: black;
        color: white;
        width: 80%;
        padding: 10px 20px;
        border-radius: 5px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        z-index: 1000;
        display: flex;
        align-items: center;
        animation: slide-down 0.1s ease-in-out;
      }
}
  