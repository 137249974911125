.product-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.sizes {
    display: flex;
    gap: 20px;
}

.product-card-image {
    max-width: 100%;
    cursor: pointer;
    min-height: 450px;
}

.product-card-add-to-cart {
    border: 1px solid black;
    background-color: transparent;
    padding: 10px 16px;
    border-radius: 6px;
    color: black;
    font-size: 14px;
    cursor: pointer;
    transition: 200ms;
}

.product-card-add-to-cart:hover {
    background-color: black;
    color: white
}

.product-card-title {
    color: black;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
}

.product-card-price {
    cursor: pointer;
}

.card-size-button {
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 100;
    color: black;
}

.card-size-button.selected {
    font-weight: 700;
}

@media (max-width: 900px) {
    .product-card-image {
        min-height: 350px;
    }
}