.checkout-page {
    margin: 0 450px;
    display: flex;
    flex-direction: column;
    gap: 100px;
}

.checkout-page-block {
    display: flex;
    flex-direction: column;
}

.checkout-page-block-title {
    margin-bottom: 30px;
    font-size: 14px;
    font-weight: 800;
}

.checkout-page-block-inputs {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.checkout-page-block-input {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 50%;
}

.checkout-page-block-input label, .checkout-page-block-input-single label {
    font-size: 12px;
    color: #818181;
}

.checkout-page-block-input input, .checkout-page-block-input select, .checkout-page-block-input-single input {
    font-size: 14px;
    border-radius: 8px;
    border: 1px solid #bbbbbb;
    background-color: transparent;
    padding: 10px;
    font-weight: 500;
}

.checkout-page-block-input input:focus, .checkout-page-block-input select:focus, .checkout-page-block-input-single input:focus {
    outline: none;
    border: 1px solid #7c7c7c;
}

.checkout-page-block-input-single {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 20px;
}

.checkout-page-block-row {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 300;
    cursor: pointer;
}

.checkout-page-block-product-image {
    width: 30px;
    min-width: 30px;
    min-height: 30px;
    object-fit: cover;
    border-radius: 6px;
    border: 1px solid #bbbbbb;
}

.checkout-page-block-price {
    margin-left: auto;
}

.checkout-page-block-row-ship {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 500;
}

.checkout-page-block-row-total {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 500;
}

@media (max-width: 900px) {
    .checkout-page {
        margin: 0 10px;
    }
}