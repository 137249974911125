  .product-page {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 100px;
  }
  
  .product-image {
    max-width: 50%;
    height: auto;
  }
  
  .product-details {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 50px 20px 0 20px
  }

  .product-details-name {
    font-size: 20px;
  }

  .product-details-description {
    font-size: 18px;
    margin-top: 50px;
  }
  
  .product-price {
    font-size: 14px;
    margin: 10px 0;
  }
  
  .product-sizes {
    display: flex;
    gap: 10px;
    margin: 10px 0;
  }
  
  .size-button {
    padding: 10px 20px;
    border: 1px solid #b0b0b0;
    cursor: pointer;
    color: #686868;
    border-radius: 3px;
    background-color: transparent;
  }
  
  .size-button.selected {
    border: 1px solid #000;
    color: black;
    font-weight: 700;
  }
  
  .product-quantity {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  
  .product-quantity button {
    padding: 10px 20px;
    border: 1px solid black;
    cursor: pointer;
    color: black;
    border-radius: 3px;
    background-color: transparent;
  }

  .product-buttons {
    display: flex;
    gap: 10px;
    width: 100%
  }
  
  .add-to-cart,
  .buy {
    border: 1px solid black;
    padding: 10px 16px;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
    width: 50%
  }

  .add-to-cart {
    background-color: transparent;
    color: black;
  }

  .buy {
    color: white;
    background-color: black;
  }


@media (max-width: 900px) {
    .product-page {
        display: flex;
        align-items: unset;
        flex-direction: column;
        margin-top: 0px;
        width: 100%;
      }

      .product-details-name {
        font-weight: 700;
      }
    

      .product-image {
        max-width: 100%;
        height: auto;
      }
      
      .product-details {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0
      }
}
  