.cart-page {
    padding-bottom: 100px;
    min-height: 20vh;
}

.cart-items {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.cart-item {
    width: 100%;
    display: flex;
    gap: 20px;
    border: 1px solid #bbbbbb;
    border-radius: 6px;
    overflow: hidden;
    background-color: #e9e9e9;
}

.cart-info {
    display: flex;
    flex-direction: column;
    padding: 10px 30px 10px 10px;
    font-size: 14px;
    color: black;
    justify-content: space-between;
    width: 100%;
}

.cart-info-row {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
}

.cart-info-delete {
    border: none;
    padding: 0;
    text-decoration: underline;
    background-color: transparent;
    display: flex;
    font-size: 14px;
    margin-left: auto;
    cursor: pointer;
    color: black;
}

.cart-media {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-width: 100px;
}

.cart-buttons {
    display: flex;
    gap: 10px;
    margin-top: 30px;
}

.cart-clear {
    border: 1px solid black;
    background-color: transparent;
    padding: 10px 16px;
    border-radius: 6px;
    color: black;
    font-size: 14px;
    cursor: pointer;
}

.checkout {
    border: 1px solid black;
    background-color: black;
    padding: 10px 16px;
    border-radius: 6px;
    color: black;
    font-size: 14px;
    cursor: pointer;
    color: white;
}