.order-table {
    padding: 100px 0;
}

.order-table {
    display: flex;
    flex-direction: column;
  }
  
  .order-title {
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 30px;
    color: black;
  }
  
  .table-header,
  .table-row {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    padding: 10px;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    gap: 10px;
  }
  
  .table-header {
    font-weight: bold;
    background-color: #f0f0f0;
  }
  
  .table-row {
    background-color: #efefef;
  }
  
  .table-row > div {
    word-break: break-word;
  }

@media (max-width: 900px) {
    .table-header {
        display: none;
    }
    .table-row {
        grid-template-columns: unset;
    }
}