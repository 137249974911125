.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.login {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    width: 300px; 
    gap: 10px;
}

.login input {
    font-size: 16px;
    border: 1px solid #d7d7d7;
    border-radius: 6px;
    padding: 10px 20px;
}

.login-form button {
    border: 1px solid black;
    background-color: black;
    padding: 10px 16px;
    border-radius: 6px;
    color: black;
    font-size: 14px;
    cursor: pointer;
    color: white;
}