.loading-spinner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1000;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid black;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

.table {
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 6px;
  margin: 100px 0;
}

.table-top {
  color: rgb(0, 0, 0);
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.table-top button {
  font-size: 16px;
  color: rgb(0, 0, 0);
  font-weight: 600;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;
  font-size: 12px;
}

.custom-table-header {
  color: #000000;
  background-color: transparent;
}

.custom-table-body {
  border-radius: 6px;
  background-color: #f8f8f8;
  border: 1px solid #c6c6c6;
  margin-top: 30px;
}

.custom-table-row {
  display: flex;
  border-bottom: 1px solid #c6c6c6;
}

.custom-table-cell {
  flex: 1;
  padding: 20px;
  color: black;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.custom-table-cell img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border: 1px solid #c6c6c6;
  margin-right: 10px;
}

.custom-table-cell:last-child {
  border-right: none;
}

.custom-table-row:last-child {
  border-bottom: none;
}


.custom-table-cell input, select {
  background-color: transparent;
  border: none;
  color: white;
  width: 100%;
  font-size: 12px;
}

.custom-table-cell input:focus, .custom-table-cell select:focus {
  background-color: transparent;
  border: none;
  color: white;
  outline: none;
}


.popup {
  padding-top: 100px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.popup-inner {
  position: relative;
  padding: 0 40px;
}

.popup-inner form {
  display: flex;
  gap: 20px;
}

.popup-inner form button {
  background-color: rgb(19, 47, 188);
  color: rgb(232, 232, 232);
  border-radius: 6px;
  border: none;
  padding: 7px 10px;
  cursor: pointer;
}

.popup-header {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 50px;
}

.popup-header button {
  border-radius: 6px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #646464;
  color: black;
  background-color: transparent;
  cursor: pointer;
}

.popup-header button svg {
  width: 20px;
  height: 20px;
}

.popup-block {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.popup-block-title {
  font-size: 18px;
  font-weight: 600;
  color: black;
  margin-bottom: 20px;
}

.popup-block-form {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid #e0e0e0;
  margin-bottom: 30px;
  background-color: #ffffff;
}

.popup-block-form label {
  margin-bottom: 5px;
  font-size: 12px;  
  color: #767676
}

.popup-block-form input, .popup-block-form textarea, .popup-block-form select {
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  color: black;
  padding: 7px;
  margin-bottom: 20px;
}

.popup-block-form textarea {
  min-height: 200px;
  font-family: 'Helvetica Neue';
}

.popup-block-form textarea:focus {
  outline: none;
  border: 1.5px solid rgb(185, 185, 185);
  font-family: 'Helvetica Neue';
}

.popup-block-form input:focus,   .popup-block-form select:focus {
  outline: none;
  border: 1.5px solid rgb(185, 185, 185);
}

.popup-block-images {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.popup-block-images label {
  width: 70px;
  height: 70px;
  border: 1.5px solid rgb(222, 222, 222);
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  cursor: pointer;
}

.uploaded-image img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border: 1.5px solid rgb(222, 222, 222);
}

.popup-block-images button svg {
  width: 30px;
  height: 30px;
}

.popup-block-sizes {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.popup-block-sizes div {
  border: 1px solid rgb(195, 195, 195);
  padding: 5px;
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.size-quantity-pair {
  display: flex;
  gap: 5px;
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


@media (max-width: 900px) {


      .popup-inner {
        padding: 0 10px;
        margin-bottom: 100px;
    }

    .popup-inner form {
        flex-direction: column;
        gap: 0;
    }

    .popup-block {
        width: auto;
    }
}