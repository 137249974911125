.header {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.01);
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(10px);
    -moz-backdrop-filter: blur(10px);
    align-items: center;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000000;
  }
  
  .header-menu {
    justify-content: space-between;
    align-items: center;
    padding: 20px 50px;
    display: flex;
  }
  
  .header-menu-item {
    display: flex;
    align-items: center;
  }

  .header-menu-items {
    display: flex;
    gap: 20px;
  }
  
  .logo {
    width: 100%;
    height: 100%;
    max-width: 80px;
    object-fit: cover;
  }
  
  .cart-icon {
    width: 100%;
    height: 100%;
    max-width: 30px;
    object-fit: cover;
  }
  
  .cart-container {
    position: relative;
  }
  
  .cart-count {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 18px;
    height: 18px;
    background-color: black;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: bold;
  }
  
  @media (max-width: 900px) {
    .header-menu {
      padding: 20px 20px;
    }
  }
  