.footer-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 100px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 200px 0 50px 0;
    color: black;
}

.footer-logo {
    font-size: 16px;
    font-weight: 500;
}

.footer-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    font-size: 12px;
    color: black;
    gap: 5px;
    font-weight: 500;
}

.footer-policy {
    text-decoration: none;
    font-size: 12px;
    color: black;
    font-weight: 500;
}

@media (max-width: 900px) {
    .footer-container {
        margin: 50px 0 50px 0;
    }
}